@import 'src/assets/styles/variables';

.slick-dots {
  display: none;
  bottom : 70px;
  width: 100%;
  @media screen and (max-width: $small-screen) {
    bottom : 30px;
  }
  > li {
    list-style-type: none;

    &.slick-active > button {
      background : $main-color;
    }

    > button {
      width         : 16px;
      height        : 16px;
      border        : 1px solid $main-color;
      border-radius : 50%;

      &:before { content: none; }
    }
  }
}