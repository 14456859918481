//? Declarations

$header-size            : 209px;
$footer-size            : 266px;
$page-vertical-offset   : 40px;
$download-image-width   : 160px;
$download-image-height  : 57px;
$page-horizontal-offset : 130px;
$page-title-size        : 70px;
$title-page-height      : calc(100vh - #{$page-title-size + $header-size + $page-vertical-offset * 2});

//? Helpers

$radius               : 8px;
$normal-fs            : 16px;
$small-fs             : 14px;
$big-radius           : 12px;
$small-radius         : 5px;
$btn-radius           : 30px;
$default-block-shadow : 0 0 10px transparentize(#000, .87);

//? Screens

$large-screen             : 2000px;
$medium-screen            : 1700px;
$largeToMedium-screen     : 1200px;
$mediumToSmall-screen     : 1024px;
$small-screen             : 900px;
$monoSmall-screen         : 700px;


$max-screen               : 1650px;
$tablet-screen            : 992px;
$app-mobile-screen        : 900px;
$mobile-screen            : 664px;
$small-mobile-screen      : 520px;
$extra-small-mobile-screen: 420px;

//? Colors

$orange           : #008B48;
$red              : #DE0404;
$light-red        : #F60065;
$gray             : #EEEEEE;
$white            : #ffffff;
$black            : #000000;
$blue             : #4267B2;
$pink             : #F85061;
$gray-dark        : #8E8E8E;
$text-gray        : #8D8D8D;
$light            : #F6F6F6;
$light-gray       : #cccccc;
$yellow           : #F1D02C;
$light-yellow     : #fbc43a;
$dark-gray        : #EFF0F1;
$light-gray       : #CFCFCF;
$very-light-gray  : #F9F9F9;
$main-color       : #008B48;
$green            : #008B48;
$light-green      : #0bbf6b;
$main-color-light : transparentize($main-color, .9);
$broken-black     : #303030;
$text-gray-light  : transparentize(#334856, .4);
$purple           : #EE00FF;
$gold             : #D2AC67;

$black-hover        : #000000;
$orange-hover       : #ee5d14;
$blue-hover         : #276cda;

$black-active        : #2f2f2f;
$orange-active       : #f05000;
$blue-active         : #1262e4;

// #region Alternative styles

$gradient         : linear-gradient(to right, $blue, #5e98d4);
$shadow           : 0 0 10px rgba(0, 0, 0, .1);
$shadow-dark      : 0 0 10px rgba(0, 0, 0, .2);
$error-shadow     : 0 0 15px transparentize($red, 0.6);
$blue-shadow      : 0 0 40px rgba($blue, .2);
$blue-dark-shadow : 0 0 40px rgba($blue, .4);

$laptop-size : 1024px;
$tab-size    : 768px;
$phone-size  : 520px;

// #endregion
$body-color : $light;

$modal-z      : 1000;
$right-side-z : $modal-z + 1;
$header-z     : 500;

$left-side-width        : 17.5vw;
$left-side-min-width    : 220px;
$left-side-closed-width : 100px;
$left-side-transition   : .3s;
$header-height          : 60px;
$padding-vw             : 8vw;
$padding                : 8%;
$input-height           : 50px;
$button-height          : 50px;
// #endregion

:export {
  headerSize: $header-size;
  smallScreen: $small-screen;
  mediumScreen: $medium-screen;
  tabletScreen: $tablet-screen;
  mobileScreen: $mobile-screen;

  gray: $gray;
  mainColor: $main-color;
}