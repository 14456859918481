@import '../helper.scss';
@import '../variables.scss';

$spaceamounts: (
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
  56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
  81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 110, 120, 130, 140, 150,
  160, 170, 180, 190, 195, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 
  380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580,
  590, 600, 610, 620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780,
  790, 800, 810, 820, 830, 840, 850, 860, 870, 876, 880, 890, 900, 910, 920, 930, 940, 950, 960, 970, 980,
  990, 1000, 1024, 1100, 1200, 1300, 1400, 1440, 1500, 1600, 1700, 1800, 1900, 1920, 2000, 2500
);

@each $space in $spaceamounts {

  // margin

  .G-m-#{$space} {
    margin: #{$space}px;
  }

  .G-my-#{$space} {
    margin-top: #{$space}px;
    margin-bottom: #{$space}px;
  }

  .G-mx-#{$space} {
    margin-left: #{$space}px;
    margin-right: #{$space}px;
  }

  .G-mt-#{$space} {
    margin-top: #{$space}px;
  }

  .G-mb-#{$space} {
    margin-bottom: #{$space}px;
  }

  .G-mr-#{$space} {
    margin-right: #{$space}px;
  }

  .G-ml-#{$space} {
    margin-left: #{$space}px;
  }

  // padding

  .G-p-#{$space} {
    padding: #{$space}px;
  }

  .G-py-#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }

  .G-px-#{$space} {
    padding-left: #{$space}px;
    padding-right: #{$space}px;
  }

  .G-pt-#{$space} {
    padding-top: #{$space}px;
  }

  .G-pb-#{$space} {
    padding-bottom: #{$space}px;
  }

  .G-pr-#{$space} {
    padding-right: #{$space}px;
  }

  .G-pl-#{$space} {
    padding-left: #{$space}px;
  }

  .G-fs-#{$space} {
    font-size: #{$space}px;
  }

  .G-w-#{$space}px {
    width: #{$space}px;
  }

  .G-w-#{$space}vw {
    width: #{$space}vw;
  }

  .G-w-#{$space}vh {
    width: #{$space}vh;
  }

  .G-max-w-#{$space}px {
    max-width: #{$space}px;
  }

  .G-max-w-#{$space}vw {
    max-width: #{$space}vw;
  }

  .G-max-w-#{$space}vh {
    max-width: #{$space}vh;
  }

  .G-min-w-#{$space}px {
    min-width: #{$space}px;
  }

  .G-min-w-#{$space}vw {
    min-width: #{$space}vw;
  }

  .G-min-w-#{$space}vh {
    min-width: #{$space}vh;
  }

  .G-h-#{$space}px {
    height: #{$space}px;
  }

  .G-h-#{$space}vw {
    height: #{$space}vw;
  }

  .G-h-#{$space}vh {
    height: #{$space}vh;
  }

  // max height
  .G-max-h-#{$space}px {
    max-height: #{$space}px;
  }

  .G-max-h-#{$space}vw {
    max-height: #{$space}vw;
  }

  .G-max-h-#{$space}vh {
    max-height: #{$space}vh;
  }

  .G-min-h-#{$space}px {
    min-height: #{$space}px;
  }

  .G-min-h-#{$space}vw {
    min-height: #{$space}vw;
  }

  .G-min-h-#{$space}vh {
    min-height: #{$space}vh;
  }
}

.G-ml-auto { margin-left : auto; }
.G-mr-auto { margin-right: auto; }
.G-mt-auto { margin-top : auto; }
.G-mb-auto { margin-bottom: auto; }
.G-mx-auto { margin: 0 auto; }
.G-my-auto { margin: auto 0; }
.G-m-auto { margin: auto; }