.onMinuteLogic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #000000;
    .second {
        font-size: 18px;
        color: #008B48;
    }
    .resendButton {
        background: none;
        color: #008B48;
        border: none;
        outline: none;
    }
}