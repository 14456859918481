@import 'assets/styles/variables';

.alertify-notifier {
  z-index: 10000000 !important;
  .ajs-message.ajs-error.ajs-visible, .ajs-message.ajs-success.ajs-visible {
    &.ajs-message.ajs-error.ajs-visible { 
      width: max-content !important;
      border-radius: 10px;
      background: white;
      box-shadow:0px 0px 20px 2px rgba(0, 0, 0, 0.349);
      border-left: 10px solid red;

      .MuiAlert_standardError_child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &.ajs-message.ajs-success.ajs-visible {
      width: max-content !important;
      border-radius: 10px;
      background: rgb(255, 255, 255);
      box-shadow:0px 0px 20px 2px rgba(0, 0, 0, 0.349);
      border-left: 10px solid rgb(0, 255, 0);

      .MuiAlert_standardError_child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}