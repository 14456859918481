@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?ozegs');
  src:  url('../../fonts/icomoon.eot?ozegs#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?ozegs') format('truetype'),
    url('../../fonts/icomoon.woff?ozegs') format('woff'),
    url('../../fonts/icomoon.svg?ozegs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Group-5502:before {
  content: "\e900";
}
.icon-Group-5503:before {
  content: "\e901";
}
.icon-Group-5504:before {
  content: "\e902";
}
.icon-Group-5505 .path1:before {
  content: "\e903";
  color: rgb(255, 255, 255);
}
.icon-Group-5505 .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: none;
}
.icon-Group-5505 .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Group-5505 .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Group-5507:before {
  content: "\e907";
}
.icon-Group-5510:before {
  content: "\e908";
}
.icon-Group-5512:before {
  content: "\e90a";
}
.icon-Group-5513:before {
  content: "\e90b";
}
.icon-Group-5514:before {
  content: "\e90c";
}
.icon-Group-5515:before {
  content: "\e90d";
}
.icon-Group-5518:before {
  content: "\e90e";
}
.icon-Group-5520:before {
  content: "\e90f";
}
.icon-Group-5522:before {
  content: "\e910";
}
.icon-Group-5524:before {
  content: "\e911";
}
.icon-Group-5527:before {
  content: "\e912";
}
.icon-Group-5529:before {
  content: "\e913";
}
.icon-Group-5532:before {
  content: "\e914";
}
.icon-Group-5534:before {
  content: "\e915";
}
.icon-Group-5535:before {
  content: "\e916";
}
.icon-Group-5537:before {
  content: "\e917";
}
.icon-Group-5539:before {
  content: "\e918";
}
.icon-Group-5541:before {
  content: "\e919";
}
.icon-Group-5543:before {
  content: "\e91a";
}
.icon-Group-5545:before {
  content: "\e91b";
}
.icon-Group-5548:before {
  content: "\e91c";
}
.icon-Group-5549:before {
  content: "\e91d";
}
.icon-Group-5553:before {
  content: "\e91e";
}
.icon-Group-5554:before {
  content: "\e91f";
}
.icon-Group-5556:before {
  content: "\e920";
}
.icon-Group-5559:before {
  content: "\e921";
}
.icon-Group-5561:before {
  content: "\e922";
}
.icon-Group-5562:before {
  content: "\e923";
}
.icon-Group-5032:before {
  content: "\e924";
}
.icon-Group-5663:before {
  content: "\e925";
}
.icon-facebook-4:before {
  content: "\e926";
}
.icon-Group-5749:before {
  content: "\e927";
}
.icon-Group-5791:before {
  content: "\e909";
}
