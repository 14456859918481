@import 'src/assets/styles/variables';


.brand_coverMain {
    .Brand_cover {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        height: 590px;
        width: 100%;
        background-size: cover !important;
        position: relative;
        z-index: 3;
        max-width: 1660px;
        margin: 30px auto 0;
        @media screen and (max-width: $medium-screen) {
            height: 400px;
            margin-bottom: 0px;
        }
        @media screen and (max-width:1200px) {
            margin-top: 20px;
        }
        @media screen and (max-width:$monoSmall-screen ) {
            height: 140px;
            margin-bottom: 0px;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 400px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.473) 0%, rgba(0, 0, 0, 0) 100%);
            @media screen and (max-width: $medium-screen) {
                height: 130px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.473) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

    }

    .detail_Brand {
        margin: 20px 0;
        width: 100%;
        padding: 0 130px;    
        @media screen and (max-width:$medium-screen) {
            padding: 0 14px 0;
        }    
        .detail {
            display: flex;
            flex-direction: column;
            @media screen and (max-width: $medium-screen) {
                justify-content: end;
            }
            .name {
                font-size: 40px;
                color: rgb(0, 0, 0);
                font-weight: bold;
                @media screen and (max-width: $medium-screen) {
                    font-size: 24px;
                }
                @media screen and (max-width: $monoSmall-screen) {
                    font-size: 16px;
                }
            }
            .count {
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }
    }

    .description {
        font-size: 16px;
        max-width: 1660px;
        // margin: 20px auto 0;
        padding: 20px 130px 0;

        @media screen and (max-width:$medium-screen) {
            padding: 20px 14px 0;
        }
        
        @media screen and (max-width:$small-screen) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
