@import '../helper.scss';
@import 'assets/styles/variables';

.G-page { 
  padding    : $page-vertical-offset $page-horizontal-offset;
  min-height : calc(100vh - #{$header-size});
  margin     : 0 auto;

  .G-no-text-decoration {
    text-decoration : none !important;
  }

  .G-page-title {
    color         : #000000;
    width         : 100%;
    height        : auto;
    margin        : 0;
    position      : relative;
    font-size     : 24px !important;
    text-align    : left !important;
    margin-bottom : 34px;
    cursor: auto;
    @include bold-font();
    @media screen and (max-width: $mediumToSmall-screen) {
      margin-bottom : 16px;
    }

    &.P-shadow-text {
      width      : auto;
      font-size  : 100px;
      margin-top : 40px;
    }

    > button, .P-button {

      color           : black;
      border          : 1px solid black;
      height          : 50px;
      padding         : 0 40px;
      display         : flex;
      font-size       : 16px;
      background      : transparent;
      align-items     : center;
      border-radius   : $radius;
      text-decoration : none;
      @include normal-font;
    }
  }

  .G-page-min-height {
    min-height : 300px;
  }
}

.G-invalid-text {
  color : red !important;
}

.G-invalid-select .P-select-header {
  border : 1px solid red !important;
  border-radius: 0 !important;
}

.G-show-action-button {
  color           : $main-color;
  height          : 50px;
  border          : 1px solid $main-color;
  margin          : 0 auto 120px;
  display         : table;
  padding         : 0 40px;
  font-size       : 16px;
  background      : transparent;
  align-items     : center;
  line-height     : 50px;
  border-radius   : $small-radius;
  text-decoration : none;
  @include normal-font;
  &:hover {
    color      : white;
    background : $main-color;
  }
}

.G-fields-form {

  display         : flex;
  flex-wrap       : wrap;
  justify-content : space-between;

  .G-field {
    margin-bottom : 30px;
  }

  .G-field-wrap, .G-half-field-wrap {

    width         : 100%;
    margin-bottom : 25px;

    &:last-of-type {
      margin-bottom : 15px;
    }

    &.G-half-field-wrap {
      width : calc(50% - 10px);
    }

    > .G-field {
      margin-bottom : 0;
    }

    > .G-field-description {
      margin-top : 10px;
    }

    > h4 {

      margin    : 0 0 5px;
      font-size : $normal-fs;
      @include normal-font;
    }
  }

  .G-form-button {
    width         : 100%;
    color         : white;
    border        : 0;
    height        : 46px;
    font-size     : 16px;
    margin-top    : 25px;
    background    : $main-color;
    border-radius : $small-radius !important;

    &.G-form-button-transparent {
      color          : black;
      margin-top     : 10px;
      background     : none;
      padding-right  : 0;
      text-transform : capitalize;
    }
  }

  a.G-form-button {
    display         : flex;
    align-items     : center;
    justify-content : center;
    text-decoration : none;
  }

  .G-form-checkbox {

    width   : auto;
    cursor  : pointer;
    margin  : 10px auto 5px 0;
    display : flex;

    > .rc-checkbox {

      display     : flex;
      align-items : center;
    }

    > h3 {

      margin    : 0 0 0 15px;
      font-size : $normal-fs;
      @include normal-font;
    }
  }
}

.G-field, .G-half-field {

  width         : 100%;
  height        : 46px;
  border        : 1px solid $light-gray;
  padding       : 0 20px;
  display       : flex;
  align-items   : center;
  border-radius : $small-radius;

  &.G-half-field {
    width : calc(50% - 10px);
  }

  > input {

    width     : 100%;
    border    : 0;
    font-size : $normal-fs;
  }

  > p {

    height        : 20px;
    font-size     : $normal-fs;
    white-space   : nowrap;
    border-right  : 2px solid $light-gray;
    margin-right  : 10px;
    padding-right : 10px;

    &.G-field-apply {

      color        : $main-color;
      cursor       : pointer;
      margin       : 0 0 0 10px;
      padding      : 0 0 0 10px;
      border-left  : 2px solid $light-gray;
      border-right : 0;
    }
  }


  &.G-field-textarea {

    height  : 150px;
    padding : 12px 0;

    > textarea {

      width     : 100%;
      border    : 0;
      height    : 100%;
      padding   : 0 20px;
      font-size : $normal-fs;
    }
  }
}

.pac-container {
  z-index : 20000;
}

.G-number-list {
  list-style-type : decimal;
}

.G-pink {
  color : $pink !important;
}

.G-back-icon {
  width         : 100%;
  color         : $text-gray;
  cursor        : pointer;
  font-size     : 26px;
  position: absolute;
  left: -30px;
}

.G-discount-label {

  top             : 15px;
  left            : 15px;
  color           : white;
  padding         : 5px;
  z-index         : 1;
  display         : flex;
  position        : absolute;
  font-size       : 14px;
  text-align      : center;
  background      : $pink;
  align-items     : center;
  border-radius   : 4px;
  pointer-events  : none;
  justify-content : center;

  > .icon-back {

    margin    : 2px 3px 0 0;
    font-size : 8px;
  }
}

.G-info-span {

  width           : 25px;
  color           : white;
  float           : left;
  height          : 25px;
  border          : 2px solid #d0be66;
  text-align      : center;
  background      : #F6E178;
  margin-right    : 15px;
  border-radius   : 50%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  padding         : 1px 1px 2px 1px;
}

.G-see-more-label {
  color           : $main-color;
  height          : 100%;
  display         : flex;
  font-size       : $normal-fs;
  align-items     : center;
  white-space     : nowrap;
  margin-left     : auto;
  text-decoration : none;
  @include normal-font;
}

.G-simple-table {

  border  : 1px solid black;
  padding : 5px;

  tr, td {

    border  : 1px solid black;
    padding : 10px;
  }
}

.G-square-image-block {
  padding    : 5px;
  min-width  : 80px;
  min-height : 120px;
  max-height : 120px;
  box-shadow : $default-block-shadow;
}


.G-full-width {
  width : 100%;
}

.G-half-width {
  width : 50%;
}

// #region Normalizers

.G-normal-link {
  text-decoration : none;
}

.G-textCenter {
  text-align: center;
}

// #endregion Normalizers

.G-cursor-pointer {
  cursor : pointer;
}

.G-cursor-text {
  cursor : text;
}

// #region Other

.P-no-data {
  position   : absolute;
  text-align : center;
  color      : gray;
  left       : 0 ;
  right      : 0;
  top        : 40%;

  > .P-desc {
    font-size : 25px;
    cursor: text;

  }
}