@import '../../assets/styles/variables.scss';

.G-page-content {
  padding-top : 0;
  min-height: calc(100vh - ($footer-size + $header-size));
  position: relative;
  max-width: 1920px;
  margin:0 auto;
}

.P-image-parent, .P-home-discounted-products-list-item {
  overflow: hidden;
  .P-image {
    transform: scale(1.4) !important;
  }
}

.P-products-list-item {
  .P-image {
    transform: scale(1.4) !important;
  }
}

#status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 31px;
  border-radius: 16px;
  color: white;
  font-size: 14px;
}

.G-clr-light-gray-new {
  background: gray;
}

.G-clr-yellow-new {
  background: yellow;
}

.G-clr-orange-new {
  background: orange;
}

.G-clr-red-new {
  background: red;
}

.G-clr-main-new {
  background: #008B48;
}



.G-clr-Pending {
  background: #C2C2C2;
}

.G-clr-Scheduled {
  background: #FFAA00;
}

.G-clr-Collecting {
  background: #4ACF4A;
}

.G-clr-Delivering {
  background: #4ACF4A;
}

.G-clr-Finished {
  background: #008B48
}

.G-clr-Canceled {
  background: #FE003C
}



.P_logo_container {
  cursor: pointer;
  .logo {
    width: 103px;
    height: 66px;
    @media screen and (max-width: $medium-screen) {
      height: 40px !important;
      width: 74px;
    }
  }
}

.filterInnerTitle {
  display: none;
}

#newButtonDisabled {
  padding: 16px 10px !important;
  text-align: center;
  min-height: 53PX;
  @media screen and (max-width: $medium-screen) {
    padding: 5px 15px !important;
  }
}


.newButtonDisabled {
  background: transparent !important;
  width: 100%;
  border-radius: 12px;
  color: #02864B;
  border: 1px solid #02864B !important;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  @media screen and (max-width: $medium-screen) {
    font-size: 16px;
  }
}


@media screen and (max-width: $monoSmall-screen) {
  .MainContentIosNavBarCloseUseHEight {
    height:calc(100% - 70px) !important;
    overflow: auto !important;
  }
}

// .ReactModalPortal {
//   .ril__toolbarItem {
//     button {
//       &:disabled {
//         background: initial !important;
//       }
//     }
//   }
// }


.isDiscounted {
  color: #ff0000!important;
}