.P-range-track {
  width         : 100%;
  height        : 4px;
  background    : $gray;
  margin-top    : 30px;
  margin-bottom : 30px;

  .P-range-track-active {
    width      : 100%;
    height     : 5px;
    align-self : center;
  }
}

.P-range-thumb {
  width         : 30px;
  height        : 30px;
  border        : 3px solid $main-color;
  background    : white;
  border-radius : 50%;
}