@import '../helper';
@import 'assets/styles/variables';

:root {
  --custom-font-family: 'Mardoto-Light';
}

* {
  margin      : 0;
  padding     : 0;
  outline     : none;
  box-sizing  : border-box;
  font-family: var(--custom-font-family);
}

.ReactModal__Content{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.274);
}


[pointer] { cursor: pointer; }

::-webkit-scrollbar-track { background: #F1F1F1; }
::-webkit-scrollbar {
  width  : 5px;
  height : 5px;
}

::-webkit-scrollbar-thumb {
  
  background    : rgba(133, 133, 133, 0.432);
  border-radius : $radius;

  &:hover { background: $text-gray; }
}

::-moz-selection { /* Code for Firefox */
  color: $white;
  background: $main-color;
}

::selection {
  color: $white;
  background: $main-color;
}

input, select, textarea {
  &:-webkit-autofill { box-shadow: 0 0 0 1000px #FFF inset; }
  &::placeholder { color: $light-gray; }
}

input[type=file] { display: none; }

h1, h2, h3, h4, h5, h6 { word-break: break-word;cursor: pointer; }
h1, h2, h3, h4, h5, h6, b, strong { @include bold-font; }

h3 { font-size: $normal-fs; }

body {
  float      : left;
  width      : 100%;
  overflow-x : hidden;
  min-height : 100vh;

  iframe {
    z-index: -1 !important;
  }

  // > #P-content {
  //   max-width: 2200px;
  //   margin: 0 auto;
  // }
}

#PortalBarUnder {
  width: 100%;
  height: 300vh;
  position: absolute;
  left: 0;
  top: 0;
  background: #2d7a11;
  pointer-events: none;
}

button {

  cursor     : pointer;
  transition : color .4s, opacity .4s, background .4s;

  
  &[disabled] {
    color      : white;
    opacity    : .5;
  }
}

textarea {
  
  resize      : none;
  height      : 100px;
  padding-top : 10px;
}