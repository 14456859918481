@import 'assets/styles/variables';

.rc-checkbox:hover .rc-checkbox-inner {
  border-color : $main-color !important;
}

.rc-checkbox-input:focus + .rc-checkbox-inner{
  border-color: #d9d9d9 !important;
}

.G-radio.rc-checkbox > .rc-checkbox-inner {
  border-radius: 50%;
}

.rc-checkbox {

  > .rc-checkbox-input {

    width  : 100%;
    height : 100%;
  }

  > .rc-checkbox-inner {

    width      : 22px;
    height     : 22px;
    box-shadow : 0 0 2px transparentize(#000, .92);

    &::after {
      top    : 2px;
      left   : 6px;
      width  : 5px;
      height : 10px;
    }
  }

  &.rc-checkbox-checked > .rc-checkbox-inner {
    border-color     : $main-color !important;
    background-color : $main-color;
  }
}

.rc-checkbox.P-radio > span {

  border-radius : 50%;

  &::after {

    top       : -20px !important;
    left      : 5px !important;
    color     : white;
    border    : 0;
    content   : '\2022';
    font-size : 50px !important;
  }
}