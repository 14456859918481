
@import 'src/assets/styles/helper';
@import 'src/assets/styles/variables';

.cardShimmer {
    position: relative;
    background-color: #FFF;
    border-radius: 6px;
    overflow: hidden;
    .shimmerBG {
        animation-duration: 2.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: #ddd;
        background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
        background-size: 1200px 100%;
        height: 100%;
        span {
            opacity: 0;
        }
    }
}

.P-row-content-category {
    display: flex;
    flex-direction: column;
    gap: 7px;
    @media screen and (max-width: $largeToMedium-screen) {
        padding-bottom: 20px;
    }
    a {
        &:hover {
            font-weight: bolder;
        }
    }

    .row-category {
        font-size: 16px;
        color: #1d1d1d;
    }

}

.shimmer-category-filter {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    .P-row-title-shimmer-loading{
        display: flex;
        gap: 10px;
        background: transparent;
        height: 30px;
        align-items: center;
        .shimmerChild0{
            min-width: 30px;
            border-radius: 5px;
        }

        .shimmerChild1{
            width: 100%;
            height: 20px;
            border-radius: 50px;
        }
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}