/* Sidebar.css */
@import 'assets/styles/variables';

.product_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .product-item {
        display: flex ;
        gap: 10px;

        .P-image-parent {
            min-width: 107px;
            width: 107px;
            height: 109px;
            border-radius: 5px;
            background-color: #eef1f3;
            position: relative;
            .P-image {
                width: 80%;
                height: 80%;
                object-fit: cover;
                transition: all .3s;
                transform: scale(1);
            }
        }

        .right_content {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            gap: 5px;
            .favourite_item_product {
                position: relative !important;
                left: unset;
                top: unset;
                right: unset;
                display: flex;
                margin-left: auto;
            }

            .P-product-name {
                max-width: 80%;
                font-size: 14px;
                display: -webkit-box;           
                -webkit-box-orient: vertical;  
                overflow: hidden;              
                text-overflow: ellipsis;        
                -webkit-line-clamp: 2;          
                max-height: 4.5em;              
                line-height: 1.5em;            
            }

            .mainButton {
                height: fit-content !important;
                padding: 5px !important;
                min-height: fit-content !important;
                margin-top: auto !important
            }

        }
    }
}

.P-product-ItemInfo {
    .sidebar {
        top: 55%;
    }
}

.P-home-page {
    .sidebar {
        @media screen and (max-width: $small-screen) {
            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, and Edge */
            }
            display: none;
            position: relative ;
            border-radius: 0 ;
            width: unset ;
            top: 0;
            right: 10px;
            width: 100%;
            box-shadow: unset;
            .right_content{
                width: 100%;
            }
        }

    }
}

.sidebar {
    position: fixed;
    z-index: 99;
    width: 359px;
    height: max-content;
    max-height: 610px;
    background-color: #fff;
    box-shadow: -6px 0 21px #0003;
    border-radius: 10px;
    right: 20px;
    top: 30%;
    padding: 13px;
    visibility: hidden;
    .title_slidebar {
        font-weight: bold;
    }
}
  
/* When the sidebar is open */
.sidebar.open {
    visibility: visible;
    transform: translateX(0);
    max-height: 420px;
    overflow: auto;
    display: block;
}
  
.close-btn {
    display: flex;
    color: #fff;
    border: none;
    // padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    > svg {
        width: 15px;
    }
}
  