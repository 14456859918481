.ladda-image {
  cursor: initial !important;
  opacity: 1 !important;
  padding-right: 0 !important;
  .ladda-spinner {
    background: transparentize(#000, .8) !important;
  }
}

.disablecolor{
  color: white !important;
}

.ladda-button {
  position: relative;
  overflow: hidden;
  .ladda-spinner {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background: inherit;
    position: absolute;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
  }
  .ladda-label {
    position: relative;
    z-index: 3;
  }
  .ladda-progress {
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    background: transparentize(#000, .8);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.1s linear all !important;
    -moz-transition: 0.1s linear all !important;
    -ms-transition: 0.1s linear all !important;
    -o-transition: 0.1s linear all !important;
    transition: 0.1s linear all !important;
  }
  &[data-loading] .ladda-progress {
    opacity: 1;
    visibility: visible;
  }
  .ladda-spinner, .ladda-label {
    -webkit-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
    -moz-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
    -ms-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
    -o-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
  }
  &[data-style=zoom-in] {
    -webkit-transition: 0.3s ease all !important;
    -moz-transition: 0.3s ease all !important;
    -ms-transition: 0.3s ease all !important;
    -o-transition: 0.3s ease all !important;
    transition: 0.3s ease all !important;
    .ladda-spinner, .ladda-label {
      -webkit-transition: 0.3s ease all !important;
      -moz-transition: 0.3s ease all !important;
      -ms-transition: 0.3s ease all !important;
      -o-transition: 0.3s ease all !important;
      transition: 0.3s ease all !important;
    }
  }
  &[data-style=zoom-out] {
    -webkit-transition: 0.3s ease all !important;
    -moz-transition: 0.3s ease all !important;
    -ms-transition: 0.3s ease all !important;
    -o-transition: 0.3s ease all !important;
    transition: 0.3s ease all !important;
    .ladda-spinner, .ladda-label {
      -webkit-transition: 0.3s ease all !important;
      -moz-transition: 0.3s ease all !important;
      -ms-transition: 0.3s ease all !important;
      -o-transition: 0.3s ease all !important;
      transition: 0.3s ease all !important;
    }
  }
  &[data-style=expand-right] {
    .ladda-spinner {
      right: -6px;
    }
    &[data-size="s"] .ladda-spinner, &[data-size="xs"] .ladda-spinner {
      right: -12px;
    }
    &[data-loading] {
      .ladda-spinner {
        opacity: 1;
      }
      &[data-size="s"], &[data-size="xs"] {
        padding-right: 40px;
      }
    }
  }
  &[data-style=expand-left] {
    .ladda-spinner {
      left: 26px;
    }
    &[data-size="s"] .ladda-spinner, &[data-size="xs"] .ladda-spinner {
      left: 4px;
    }
    &[data-loading] {
      padding-left: 56px;
      .ladda-spinner {
        opacity: 1;
      }
      &[data-size="s"], &[data-size="xs"] {
        padding-left: 40px;
      }
    }
  }
  &[data-style=expand-up] {
    overflow: hidden;
    .ladda-spinner {
      top: -32px;
      left: 50%;
      margin-left: 0;
    }
    &[data-loading] {
      padding-top: 54px;
      .ladda-spinner {
        opacity: 1;
        top: 26px;
        margin-top: 0;
      }
      &[data-size="s"], &[data-size="xs"] {
        padding-top: 32px;
      }
      &[data-size="s"] .ladda-spinner, &[data-size="xs"] .ladda-spinner {
        top: 4px;
      }
    }
  }
  &[data-style=expand-down] {
    overflow: hidden;
    .ladda-spinner {
      top: 62px;
      left: 50%;
      margin-left: 0;
    }
    &[data-size="s"] .ladda-spinner, &[data-size="xs"] .ladda-spinner {
      top: 40px;
    }
    &[data-loading] {
      padding-bottom: 54px;
      .ladda-spinner {
        opacity: 1;
      }
      &[data-size="s"], &[data-size="xs"] {
        padding-bottom: 32px;
      }
    }
  }
  &[data-style=slide-left] {
    overflow: hidden;
    .ladda-label {
      position: relative;
    }
    .ladda-spinner {
      left: 100%;
      margin-left: 0;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        left: -100%;
      }
      .ladda-spinner {
        opacity: 1;
        left: 50%;
      }
    }
  }
  &[data-style=slide-right] {
    overflow: hidden;
    .ladda-label {
      position: relative;
    }
    .ladda-spinner {
      right: 100%;
      margin-left: 0;
      left: 16px;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        left: 100%;
      }
      .ladda-spinner {
        opacity: 1;
        left: 50%;
      }
    }
  }
  &[data-style=slide-up] {
    overflow: hidden;
    .ladda-label {
      position: relative;
    }
    .ladda-spinner {
      left: 50%;
      margin-left: 0;
      margin-top: 1em;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        top: -1em;
      }
      .ladda-spinner {
        opacity: 1;
        margin-top: 0;
      }
    }
  }
  &[data-style=slide-down] {
    overflow: hidden;
    .ladda-label {
      position: relative;
    }
    .ladda-spinner {
      left: 50%;
      margin-left: 0;
      margin-top: -2em;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        top: 1em;
      }
      .ladda-spinner {
        opacity: 1;
        margin-top: 0;
      }
    }
  }
  &[data-style=zoom-out] {
    overflow: hidden;
    .ladda-spinner {
      left: 50%;
      margin-left: 32px;
      -webkit-transform: scale(2.5);
      -moz-transform: scale(2.5);
      -ms-transform: scale(2.5);
      -o-transform: scale(2.5);
      transform: scale(2.5);
    }
    .ladda-label {
      position: relative;
      display: inline-block;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
      }
      .ladda-spinner {
        opacity: 1;
        margin-left: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
      }
    }
  }
  &[data-style=zoom-in] {
    overflow: hidden;
    .ladda-spinner {
      left: 50%;
      margin-left: -16px;
      -webkit-transform: scale(0.2);
      -moz-transform: scale(0.2);
      -ms-transform: scale(0.2);
      -o-transform: scale(0.2);
      transform: scale(0.2);
    }
    .ladda-label {
      position: relative;
      display: inline-block;
    }
    &[data-loading] {
      .ladda-label {
        opacity: 0;
        -webkit-transform: scale(2.2);
        -moz-transform: scale(2.2);
        -ms-transform: scale(2.2);
        -o-transform: scale(2.2);
        transform: scale(2.2);
      }
      .ladda-spinner {
        opacity: 1;
        margin-left: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
      }
    }
  }
  &[data-style=contract] {
    overflow: hidden;
    width: 100px;
    .ladda-spinner {
      left: 50%;
      margin-left: 0;
    }
    &[data-loading] {
      border-radius: 50%;
      width: 52px;
      .ladda-label {
        opacity: 0;
      }
      .ladda-spinner {
        opacity: 1;
      }
    }
  }
  &[data-style=contract-overlay] {
    overflow: hidden;
    width: 100px;
    box-shadow: 0px 0px 0px 2000px transparent;
    .ladda-spinner {
      left: 50%;
      margin-left: 0;
    }
    &[data-loading] {
      border-radius: 50%;
      width: 52px;
      box-shadow: 0px 0px 0px 2000px transparentize(#000, .2);
      .ladda-label {
        opacity: 0;
      }
      .ladda-spinner {
        opacity: 1;
      }
    }
  }
  border: 0;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  &[disabled], &[data-loading] {
    cursor: default;
    border-color: transparentize(#000, .93);
  }
}