@import '../helper.scss';
@import 'assets/styles/variables';

.G-bg-clr-main {
    background-color : $main-color;
}

.G-clr-main {
    color : black !important;
    font-weight: 900 !important;
    font-size: 20px;
    cursor: text ;

}

.G-clr-black {
    color: $black;
}

.G-clr-white {
    color: $white;
}

.G-clr-gray {
    color: $text-gray;
}


.G-clr-light-gray {
    color: $text-gray-light;
}

.G-clr-green {
    color: $green;
}

.G-clr-red {
    color: $red !important;
}

.G-clr-yellow {
    color: $yellow !important;
}

.G-clr-orange {
    color: $orange;
}

.G-clr-blue {
    color: $blue;
}
