@import 'assets/styles/variables.scss';

.chat_container {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 101;
  @media screen and (max-width: $mediumToSmall-screen) {
    bottom: 80px;
    right: 14px;
  }

  .chatIcon {
    background: #008B48;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    width: 63px;
    height: 63px;
    border-radius: 50px;
    @media screen and (max-width: $mediumToSmall-screen) {
      width: 53px;
      height: 53px;
    }
  }

  .chat_popup {
    position: fixed;
    right: 50px;
    bottom: 140px;
    width: 381px;
    height: 554px;
    border: 1px solid gray;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $monoSmall-screen ) {
      width: 100%;
      right: 0;
      height: 100%;
      bottom: 0;
    }


    .chat_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #008B48;
      color: $white;
      border-radius: 8px 8px 0 0;

      .chat_user {
        display: flex;
        align-items: center;

        .chat_name {
          margin-left: 10px;
          font-size: 16px;
        }
      }

      .chat_close {
        background: none;
        border: none;
        font-size: 18px;
        color: $white;
        cursor: pointer;
      }
    }

    .chat_body {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow: auto;
      .chat_group_day {
        display: flex;
        flex-direction: column;
        .date {
          text-align: center;
        }

        .chat_message {
          margin-bottom: 8px;
          padding: 8px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          gap: 2px;
  
          .time {
            font-size: 12px;
          }
          &.sender {
            background-color: #008B48;
            align-self: flex-end;
            color: white;
          }
  
          &.receiver {
            background-color: #EEEEEE;
            align-self: flex-start;
          }
        }

        .chat_image {
          display: flex;
          &.sender {
            background-color: transparent;
            .image {
              margin-left: auto;
              max-width: 50%;
            }
          }

          &.receiver {
            background-color: transparent;
            .image {
              margin-right: auto;
              max-width: 50%;
            }
          }

        }
      }


    }

    .chat_footer {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      border-top: 1px solid #EEEEEE;
      width: 96%;
      margin: 0 auto;
      margin: 0 auto;
      margin-top: auto;
     .upload_file_button {
        position: absolute;
        left: 15px;
        background: transparent;
        border: none;
        outline: none;
      }

      input[type='text'] {
        flex: 1;
        padding: 8px;
        // border: 1px solid #EEEEEE;
        border: none;
        border-radius: 8px;
        padding-left: 30px;
      }

      .send_message {
        padding: 8px 12px;
        border: none;
        border-radius: 8px;
        background-color: #008B48;
        color: $white;
        cursor: pointer;

        &:hover {
          background-color: darkcyan;
        }
      }
    }
  }
}

.isInclude {
  @media screen and (max-width: $medium-screen) {
    bottom: 210px;
  }
}