.product_loader_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5vh;

    &>div{
        width: 81%;
        display: flex;
        justify-content: center;
    }
}