@font-face {
  font-family: 'Mardoto-Light';
  src:  url('../../fonts/Mardoto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto-Bold';
  src:  url('../../fonts/Mardoto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto-Medium';
  src:  url('../../fonts/Mardoto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src:  url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src:  url('../../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src:  url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}




.G-fs-normal {
  font-size : $normal-fs !important;
}
.G-fs-small {
  font-size : $small-fs  !important;
}
.logo-image{
  vertical-align: middle;
}
.master-card-bonus{
  width: 140px;
  text-align: end;
}
.master-card-text{
  width: 240px;
}

