@import 'src/assets/styles/variables';

.promo_coverMain {
    width: 100%;
    padding: 40px 130px 0;
    width: 100%;
    @media screen and (max-width:1700px) {
        padding: 0px;
    }
    .promo_cover {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        // height: 540px;
        width: 100%;
        background-size: cover !important;
        position: relative;
        z-index: 3;
        max-width: 1660px;
        margin: 30px auto 0;
        border-radius: 19px;
        @media screen and (max-width: $medium-screen) {
            // height: 400px;
            margin-bottom: 0px;
            border-radius: 0px;
        }
        @media screen and (max-width:1200px) {
            margin-top: 20px;
        }
        @media screen and (max-width:$monoSmall-screen ) {
            // height: 140px;
            margin-bottom: 0px;
        }
        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        //     height: 400px;
        //     background: linear-gradient(to top, rgba(0, 0, 0, 0.473) 0%, rgba(0, 0, 0, 0) 100%);
        //     @media screen and (max-width: $medium-screen) {
        //         height: 130px;
        //         background: linear-gradient(to top, rgba(0, 0, 0, 0.473) 0%, rgba(0, 0, 0, 0) 100%);
        //     }
        // }

    }

    .detail_promo {
        margin: 20px 0;
        width: 100%;
        padding: 0;    
        @media screen and (max-width:$medium-screen) {
            padding: 0 14px 0;
        }    
        .detail {
            display: flex;
            flex-direction: column;
            gap: 5px;
            @media screen and (max-width: $medium-screen) {
                justify-content: end;
            }
            .name {
                font-size: 40px;
                color: rgb(0, 0, 0);
                font-weight: bold;
                @media screen and (max-width: $medium-screen) {
                    font-size: 24px;
                }
                @media screen and (max-width: $monoSmall-screen) {
                    font-size: 16px;
                }
            }
            .count {
                font-size: 16px;
                color: rgb(0, 0, 0);
            }
        }
    }
    
    .description {
        font-size: 16px;
        max-width: 1660px;
        margin: 20px auto 0;
        @media screen and (max-width:$medium-screen) {
            padding: 0px 14px 0;
        }
        @media screen and (max-width:$small-screen) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}