
.OutOfStockMainModel {
    .P-modal-content{
        i {
            display: none !important;
        }
    }

    .OutOfStockMain{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-height: 500px;
        height: 100%;

        .icon {
            margin-bottom: 32px;
        }

        .P-desc{
            font-size: 20px;
            color: #000000;
            margin-top: 21px;
            font-weight: bold;
            margin-bottom: 11px;
        }

        .P-desc2 {
            text-align: center;
            font-size: 16px;
            color: #000000;
            margin-bottom: 40px;
        }
    }
}
