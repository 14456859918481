.P-main-info_person{
    width: 112px;
    height: 112px;
    .borderImg {
        border: 1px solid black;
        border-radius: 100px;
    }
    .P-image{
        width: 112px;
        height: 112px;
        border-radius: 100px;
        object-fit: cover;
    }
    img { 
        width: 100% ;
        opacity: 0;
        cursor: pointer;
    }
    input {
        width: 100% 
    }
    
    .P-imageLoading {
        width: 112px;
        height: 112px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.PersonalInfoPage{
    > h2 {
        margin-bottom: 10px;
    }
}

